
import { FC, memo } from 'react';
import { Column } from '../../../сomponents/column';
import { BlurMiddlePersonalAccount, BlurTopPersonalAccount, Line, TableRow, TableColumn } from './referral.styled';
import { Spacing } from '../../../сomponents/spacing';
import { Text } from '../../../сomponents/text';
import { Surface } from '../../../ui-kit/surface';
import { ReferralState } from '../../../store/reducers/personal-account/referral-slice';
import { ScrollView } from '../../../ui-kit/scroll-view';
import { themes } from '../../../ui-kit/themes/theme';
import { BenefitLinkBoard } from '../../../ui-kit/benefit-link-board';

export type ReferralViewProps = {
  refCode: string | null;
  onShare: () => void;

  getNextBenefits: () => void;
  referralState: ReferralState;
};

export const ReferralView: FC<ReferralViewProps> = memo(({
  refCode,
  onShare,

  getNextBenefits,
  referralState
}) => {

  return (
    <Column horizontalAlign="center" padding={[14,18]}>
      <BlurTopPersonalAccount />
      <BlurMiddlePersonalAccount />
      <Column style={{height: 50}} verticalAlign='center' horizontalAlign='center'> 
        <Text align='center' themeColor={themes.colors.foreground} themeFont={themes.fonts.h2}>Referral</Text>
      </Column>
      <Spacing variant='Column' themeSpace={14} />
      <BenefitLinkBoard 
        refCode={refCode}
        onShare={onShare}/>
      <Spacing variant='Column' themeSpace={14} />
      <ReferralsBoard
        getNextBenefits={getNextBenefits}
        referralState={referralState}/>
    </Column>
  );
});

export type ReferralsBoardProps = {
  getNextBenefits: () => void;
  referralState: ReferralState;
};
export const ReferralsBoard: FC<ReferralsBoardProps> = memo(({referralState,getNextBenefits}) => {

  return (
    <Surface radius={20} height={350} themeColor={themes.colors.background} themeColorBorder={themes.colors.gradients.foreground}>
      <Column padding={[0,10,15,10]} style={{height: '100%'}}>
        <Column style={{height: 50, flexShrink: 0}} verticalAlign='center' horizontalAlign='center'> 
          <Text align='center' themeColor={themes.colors.primary} themeFont={themes.fonts.h2}>My Referrals - {referralState.referral.totalElements}</Text>
        </Column>
        <ScrollView onScrollEnd={getNextBenefits} style={{height: '100%', zIndex: 5}}>
          <ReferralsTable
            referralState={referralState}/>
        </ScrollView>
      </Column>
    </Surface>
  );
});

export type ReferralsTablesProps = {
  referralState: ReferralState;
};

export const ReferralsTable: FC<ReferralsTablesProps> = memo(({referralState}) => {

  return (
    <Column padding={[0,18]}>
      <TableRow>
        <TableColumn>
          <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>Name</Text>
        </TableColumn>
        <TableColumn>
          <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>Games played</Text>
        </TableColumn>
        <TableColumn>
          <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>Fee</Text>
        </TableColumn>
      </TableRow>
      <Line/>
      {
        referralState.referral.benefits.length !== 0 ? (
          referralState.referral.benefits.map(item => <>
            <TableRow>
              <TableColumn>
                <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>{item.playerName}</Text>
              </TableColumn>
              <TableColumn>
                <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>{item.played}</Text>
              </TableColumn>
              <TableColumn>
                <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>{item.fee}</Text>
              </TableColumn>
            </TableRow>
            <Line/>
          </>)
        ) : (
          <>
            <TableRow>
              <TableColumn>
                <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>-</Text>
              </TableColumn>
              <TableColumn>
                <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>0</Text>
              </TableColumn>
              <TableColumn>
                <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.ht2}>-</Text>
              </TableColumn>
            </TableRow>
            <Line/>
          </>
        )
      }
    </Column>
  );
});