
import { FC, memo } from 'react';
import { Column } from '../../../../сomponents/column';
import { Blur, ImageGameBackground, SurfaceNameGame } from './lobby.styled';
import { BenefitLinkBoard } from '../../../../ui-kit/benefit-link-board';
import { Spacing } from '../../../../сomponents/spacing';
import { Surface } from '../../../../ui-kit/surface';
import { Row } from '../../../../ui-kit/row';
import { themes } from '../../../../ui-kit/themes/theme';
import { Text } from '../../../../ui-kit/text';
import { Image } from '../../../../ui-kit/image';

import sitAndGoUsdtIcon from "../../../../ui-kit/assets/images/sit-and-go-usdt.jpg";
import bitrollIcon from "../../../../ui-kit/assets/images/bitroll.jpg";
import sitAndGoBtrtIcon from "../../../../ui-kit/assets/images/sit-and-go-btrt.jpg";

import teather from '../../../../ui-kit/assets/teather-icon.svg';
import bitrateCoin from '../../../../ui-kit/assets/bitrate-coin.png';

export type LobbyViewProps = {
  onSitAndGoBtrtRooms: () => void;
  onSitAndGoRooms: () => void;
  refCode: string | null;
  onShare: () => void;
};

export const LobbyView: FC<LobbyViewProps> = memo(({
  onSitAndGoBtrtRooms,
  onSitAndGoRooms,
  refCode,
  onShare
}) => {

  return (
    <Column style={{ position: "relative" }} horizontalAlign="center">
      <Blur />
      <Column style={{maxWidth: 500}} horizontalAlign='center' padding={[15,25,85,25]}>
        <BenefitLinkBoard 
          refCode={refCode}
          onShare={onShare}/>
        <Spacing themeSpace={20} variant='Column' />
        <CardGameView 
          padding={[3,3,3,10]} positionInfo='space-between'
          nameGame='SIT&GO' 
          urlImage={sitAndGoUsdtIcon}
          widthRatio={2.25} 
          onClick={onSitAndGoRooms}
          strokePanel={false}
          comment='Play for usdt' imageBuyIn={teather}/>
        <Spacing themeSpace={13} variant='Column' />
        <Row style={{width: '100%'}}>
          <CardGameView isSoon={true} nameGame='BITROLL' urlImage={bitrollIcon}/>
          <Spacing themeSpace={13} variant='Row' />
          <CardGameView onClick={onSitAndGoBtrtRooms} nameGame='SIT&GO' urlImage={sitAndGoBtrtIcon} padding={[3,3,3,8]} imageBuyIn={bitrateCoin}/>
        </Row>
      </Column>
    </Column>
  );
});


export type CardGameViewProps = {
  urlImage: string,
  onClick?: () => void;
  widthRatio?: number,
  heightRatio?: number,
  isSoon?: boolean,
  radius?: number,
  nameGame?: string,
  positionInfo?: 'center' | 'space-between',
  comment?: string;
  imageBuyIn?: string;
  strokePanel?: boolean;
  padding?: number | [(number | undefined)?, (number | undefined)?, (number | undefined)?, (number | undefined)?] | undefined;
};

export const CardGameView: FC<CardGameViewProps> = memo(({
  urlImage,
  isSoon = false,
  widthRatio = 1,
  heightRatio = 1,
  radius = 20,
  nameGame,
  positionInfo = 'center',
  comment,
  imageBuyIn,
  onClick,
  padding,
  strokePanel = true
}) => {

  return (
    <Surface onClick={onClick} radius={radius} style={{overflow: 'hidden', cursor: 'pointer',boxShadow: '0 6px 18px 0 rgba(170, 68, 221, 0.14)'}}>
      {isSoon && <Column padding={[20,20]} style={{position: 'absolute', height: '100%', zIndex: 10}} horizontalAlign='flex-end' verticalAlign='flex-start'>
        <Surface style={{width: 'auto'}} padding={[8,15]}>
          <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.h4}>
            Soon
          </Text>
        </Surface>
      </Column>}
      <ImageGameBackground isSoon={isSoon} src={urlImage} widthRatio={widthRatio} heightRatio={heightRatio}>
        <SurfaceNameGame padding={padding} strokePanel={strokePanel}>
          <Row 
            style={{width: '100%', height: '100%', position: 'relative', zIndex: 3}} 
            verticalAlign='center' horizontalAlign={positionInfo}>
            <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.h3}>
              {nameGame}
            </Text>
            {comment !== undefined && 
              <Surface radius={20} width={'auto'} padding={[0,0,0,10]} borderWidth={1} 
                themeColor='rgba(28, 28, 28, 0.4)' themeColorBorder={themes.colors.gray}>
                <Row verticalAlign='center' style={{height: '100%'}}>
                  <Text themeColor={themes.colors.foreground} themeFont={themes.fonts.t}>
                    {comment}
                  </Text>
                  <Spacing themeSpace={4} variant='Row' />
                  {imageBuyIn !== undefined && <Surface width={24} height={24} borderWidth={1} radius={12} 
                    themeColor='rgba(38, 39, 44, 0.6)' themeColorBorder={themes.colors.gray}>
                    <Column style={{height: '100%'}} horizontalAlign='center' verticalAlign='center'>
                      <Image src={imageBuyIn} size={12}/>
                    </Column>
                  </Surface>}
                </Row>
              </Surface>
            }
            {(comment === undefined && imageBuyIn !== undefined) && 
              <Surface style={{marginLeft: 5}} width={24} height={24} borderWidth={1} radius={12} 
                themeColor='rgba(38, 39, 44, 0.6)' themeColorBorder={themes.colors.gray}>
                <Column style={{height: '100%'}} horizontalAlign='center' verticalAlign='center'>
                  <Image src={imageBuyIn} size={12}/>
                </Column>
              </Surface>}
          </Row>
        </SurfaceNameGame>
      </ImageGameBackground>
    </Surface>
  );
});