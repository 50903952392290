import { FC, memo, useCallback, useState } from "react";
import { CircleOnline, Circle as CirleWallet, HeaderBackground, WalletBackground } from "./header.styled";
import { Row } from "../row";
import { Spacing } from "../spacing";
import { Pressable } from "../pressable";
import { Image } from "../image";
import notificationIcon from "../assets/notification.svg";
import { Circle } from "../circle";
import select from '../assets/select.svg';
import add from '../assets/add.svg';
import teather from '../assets/teather-icon.svg';
import activeGameIcon from '../assets/active-game-icon.svg';
import { Text } from '../text';
import { themes } from "../themes/theme";
import { Column } from "../column";
import { Modal } from "../modal";
import { ScrollView } from "../scroll-view";
import { useNavigate } from "react-router";
import { ActivaCard } from "../../screens/mobile/personal-account/personal-account.view";
import { useNotification } from "../../hooks/notification";
import { Surface } from "../surface";
import coin from '../../ui-kit/assets/bitrate-coin.png';
import { useAppDispatch, useTypedSelector } from "../../hooks/use-typed-selector";
import { getActiveRooms } from "../../store/reducers/sit-and-go/rooms-slice";
import { useUser } from "../../hooks/user-hook";

export type HeaderProps = {
  balance: number;
  picture: string;
  onWallet: () => void;
  onProfile: () => void;
}
export const Header: FC<HeaderProps> = memo(({
  balance,
  picture,
  onWallet,
  onProfile,
}) => {
  const [activeGame, setActiveGame] = useState<boolean>(false);
  const closeActiveGame = useCallback(() => {
    setActiveGame(false);
  }, []);
  const { activeRooms } = useTypedSelector(state => state.roomSit);
  const dispatch = useAppDispatch();
  const { authToken } = useUser();
  const onActiveGames = useCallback(() => {
    dispatch(getActiveRooms({ authToken: authToken }))
    setActiveGame(true);
  }, [authToken, dispatch]);

  const [stateNotification, setNotification] = useState<boolean>(false);
  const closeNotification = useCallback(() => {
    setNotification(false);
  }, []);
  const { notification, getNotifications } = useNotification();
  const onNotification = useCallback(() => {
    getNotifications();
    setNotification(true);
  }, [getNotifications]);

  const navigate = useNavigate();
  const onGoRoom = useCallback((type: string, roomId: number, currency: string) => {
    if (type === "SITGO") {
      navigate(`/sit-and-go/${roomId}/${currency}`);
    }
    else {

    }
  }, [navigate])

  return (
    <>
      <HeaderBackground>
        <Row style={{ width: "100%" }} horizontalAlign="space-between" verticalAlign="center">
          <Wallet onWaller={onWallet} balance={balance} />
          <Row verticalAlign="center">
            <Pressable onClick={onActiveGames} style={{ height: "100%", display: "flex", alignItems: "center" }}>
              <Image src={activeGameIcon} width={24} height={20} />
            </Pressable>
            <Spacing themeSpace={10} variant="Row" />
            <Pressable onClick={onNotification} style={{ height: "100%", display: "flex", alignItems: "center" }}>
              <Image src={notificationIcon} size={20} />
            </Pressable>
            <Spacing themeSpace={10} variant="Row" />
            <Pressable onClick={onProfile} style={{ height: "100%", display: "flex", alignItems: "center" }}>
              <Circle style={{ position: "relative" }} size={45}>
                <Image
                  style={{
                    position: "relative",
                    border: "1px solid #1C1C1C",
                    borderRadius: "50%",
                    background: "#1C1C1C"
                  }} src={picture} size={43}
                />
                <Image style={{ position: "absolute", right: "-2px", bottom: "5px" }} src={select} size={12} />
              </Circle>
            </Pressable>
          </Row>
        </Row>
      </HeaderBackground>
      <Modal isActive={activeGame} closePopup={closeActiveGame}>
        <Column padding={[0, 14, 14, 14]} style={{ height: "100%", zIndex: 10000 }} horizontalAlign="center">
          <Text themeFont={themes.fonts.h1}>Active <span style={{ color: `${themes.colors.secondary}` }}>Games</span></Text>
          <Spacing themeSpace={20} variant="Column" />
          <ScrollView style={{ maxHeight: "310px" }}>
            {activeRooms.length > 0 &&
              <>
                <Text style={{ marginLeft: "5px" }} themeFont={themes.fonts.h4} themeColor={themes.colors.gray}>SIT&GO</Text>
                <Spacing variant="Column" themeSpace={15} />
              </>
            }
            {activeRooms.map((item) =>
              <>
                <ActivaCard currency={item.currencies} status={item.status} onClick={onGoRoom} type={item.type} roomId={item.id} time={item.recovery} />
                <Spacing variant="Column" themeSpace={8} />
              </>
            )}
          </ScrollView>
        </Column>
      </Modal>
      <Modal padding={[25, 10, 0, 10]} isActive={stateNotification} closePopup={closeNotification}>
        <Column padding={[0, 14, 0, 14]} style={{ height: "100%", zIndex: 10000 }} horizontalAlign="center">
          <Text themeFont={themes.fonts.h1}>Notifications</Text>
          <Spacing themeSpace={20} variant="Column" />
          <ScrollView style={{ maxHeight: "310px", paddingBottom: "0px" }}>
            {notification.content.map((item) =>
              <>
                <Notification
                  description={item.description}
                  date={item.time}
                  isRead={item.read}
                  picture={coin}
                  type={item.type}
                />
                <Spacing variant="Column" themeSpace={5} />
              </>
            )}
          </ScrollView>
        </Column>
      </Modal>
    </>
  );
})

type WalletProps = {
  balance: number;
  onWaller: () => void;
}

const Wallet: FC<WalletProps> = memo(({ balance, onWaller }) => {
  return (
    <WalletBackground>
      <CirleWallet>
        <Image src={teather} size={15} />
      </CirleWallet>
      <Row verticalAlign="center" horizontalAlign="space-between" padding={[0, 5, 0, 38]} style={{ width: "100%" }}>
        <Column verticalAlign='center'>
          <Text style={{ marginLeft: "1px" }} themeFont={themes.fonts.t} themeColor={themes.colors.gray}>Wallet</Text>
          <Text themeFont={themes.fonts.ht2} themeColor={themes.colors.foreground}>{balance}</Text>
        </Column>
        <Pressable onClick={onWaller} style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Image src={add} size={21} />
        </Pressable>
      </Row>
    </WalletBackground>
  );
})

type NotificationProps = {
  description: string;
  date: string;
  picture: string;
  isRead: boolean;
  type: string;
}
const Notification: FC<NotificationProps> = memo(({ description, date, picture, isRead, type }) => {
  const getType = () => {
    return type === "SITGO" ?  "SIT&GO " : type === "" ? "" : `${type} `;
  }
  return (
    <Surface padding={[10, 10]} radius={20} width="100%">
      <Row verticalAlign="center" style={{ width: "100%" }}>
        <Column style={{ width: "auto", position: "relative" }}>
          <Image src={picture} size={35} />
          {!isRead && <CircleOnline />}
        </Column>
        <Spacing variant="Row" themeSpace={15} />
        <Column verticalAlign="center" horizontalAlign="flex-start">
          <Text style={{ fontSize: "12px" }} themeFont={themes.fonts.ht1}>{description}</Text>
          <Spacing variant="Column" themeSpace={5} />
          <Text themeFont={themes.fonts.ht2} themeColor={themes.colors.secondary} >{getType()}{date}</Text>
        </Column>
      </Row>
    </Surface>
  )
})