import { Navigate, Route, Routes } from "react-router";
import './App.css';
import { PrivateRoute } from "./сomponents/private-route/private-route";
import { FC } from "react";
import { PersonalAccount } from "./screens/mobile/personal-account";
import { Referral } from "./screens/mobile/referral";
import { SwiperParent } from "./screens/mobile/swiper-parent/swiper-parent";
import { RegistrationLogIn } from "./screens/registration/registration-log-in";
import { RegistrationCreate } from "./screens/registration/registration-create";
import { PrivacyPolicy } from "./screens/mobile/privacy-policy";
import { TermsAndConditions } from "./screens/mobile/terms-and-conditions";
import { Rules } from "./screens/mobile/rules";
import { SitAndGoBtrtRooms } from "./screens/mobile/rooms/sit-and-go-btrt-rooms";
import { SitAndGo } from "./screens/mobile/games/sit-and-go";
import { SitAndGoRooms } from "./screens/mobile/rooms/sit-and-go-rooms";
import { statisticsRoute } from "./screens/mobile/statistics/statistics.props";
import { Statistics } from "./screens/mobile/statistics";
import { WheelDecorate } from "./сomponents/wheel-decorate/wheel-decorate";

type RootProps = {
  isTelegram: boolean;
}
export const Root: FC<RootProps> = ({ isTelegram }) => {
  return (
    <Routes>
      <Route path="/log-in" element={<RegistrationLogIn />} />
      <Route path="/sign-up" element={<RegistrationCreate />} />

      <Route path="/test" element={<WheelDecorate />} />

      <Route path="/" element={<PrivateRoute />} >
        <Route index element={<SwiperParent />} />
        <Route path="/sit-and-go-rooms" element={<SitAndGoRooms />} />
        <Route path="/sit-and-go-btrt-rooms" element={<SitAndGoBtrtRooms />} />
        <Route path="/sit-and-go/:roomId/:currency" element={<SitAndGo />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path={statisticsRoute} element={<Statistics />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/personal-account" element={<PersonalAccount />} />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
}