
import { FC, memo, ReactNode } from 'react';
import { Blur, InformationContainer } from './terms-and-conditions.styled';
import { Column } from '../../../ui-kit/column';
import { Text } from '../../../ui-kit/text';
import { Spacing } from '../../../ui-kit/spacing';
import { themes } from '../../../ui-kit/themes/theme';
import { Surface } from '../../../ui-kit/surface';

export type TermsAndConditionsViewProps = {

};

export const TermsAndConditionsView: FC<TermsAndConditionsViewProps> = memo(() => {

  const data = [
    {
      header: <>Participant Eligibility</>,
      text: <>
        By accessing or using Bitrate's services, you affirm that you 
        are of legal gambling age in your jurisdiction, typically no 
        younger than 18 years old. Bitrate reserves the right to verify 
        the age and eligibility of participants and may request additional 
        information for this purpose.
      </>
    },
    {
      header: <>Registration Process</>,
      text: <>
        To participate in Bitrate's tournaments, users must provide accurate
        and up-to-date contact information, including a valid phone number, 
        email address, or Telegram handle. Failure to provide accurate information 
        may result in account suspension or termination.
      </>
    },
    {
      header: <>Tournament Rules</>,
      text: <>
        All participants are required to familiarize themselves with and adhere
        to the rules and regulations outlined in Bitrate's tournament guidelines.
        By participating, users agree to abide by these rules, which may include
        guidelines for predictions, scoring, deadlines, and prohibited activities.
      </>
    },
    {
      header: <>Prizes and Rewards</>,
      text: <>
        Winners of Bitrate tournaments will receive a portion of the prize 
        pool, with a percentage allocated to Bitrate for operational costs, 
        Bitrols and future development. The specific allocation of prizes will be 
        outlined in each tournament's window.
      </>
    },
    {
      header: <>Entry Fees and Payments</>,
      text: <>
        Bitrate accepts deposits in cryptocurrency to top-up player`s balance. 
        Users are responsible for covering any transaction fees associated with 
        their deposits and withdrawals. By participating, users acknowledge that 
        cryptocurrency transactions are irreversible, and Bitrate does not provide 
        refunds for any reason.
        <br/><br/>
        In the event of a large withdrawal request, Bitrate reserves the right 
        to initiate additional verification procedures, including but not limited 
        to Know Your Customer (KYC) checks. As part of this process, users may be 
        required to provide additional documentation to verify their identity and 
        the legitimacy of the transaction. Please note that processing times for 
        such withdrawals may take between 6 to 24 hours to complete, depending on 
        the volume of requests and the complexity of the verification process. 
        Bitrate will make reasonable efforts to expedite these procedures and 
        minimize any inconvenience to the user.
      </>
    },
    {
      header: <>Data Privacy and Security</>,
      text: <>
        Bitrate respects the privacy of its users and is committed to protecting 
        their personal information. User data and cookies are collected solely for 
        the purpose of facilitating gameplay and are not shared with any third parties 
        without explicit consent. Bitrate employs industry-standard security measures to 
        safeguard user information.
      </>
    },
    {
      header: <>Intellectual Property Rights</>,
      text: <>
        All intellectual property rights associated with Bitrate's platform, including 
        but not limited to trademarks, logos, and proprietary algorithms, belong 
        exclusively to Bitrate. Users agree not to reproduce, distribute, or modify 
        any content or materials without prior written consent from Bitrate.
      </>
    },
    {
      header: <>Liability and Disclaimers</>,
      text: <>
        While Bitrate strives to provide a reliable and fair gaming experience, 
        users acknowledge that participation in cryptocurrency-based activities 
        carries inherent risks. Bitrate is not liable for any losses incurred as a 
        result of system failures, technical glitches, hacker attacks, or fluctuations 
        in cryptocurrency markets. By participating, users assume full responsibility 
        for their actions and investments.
      </>
    },
    {
      header: <>Code of Conduct</>,
      text: <>
        Users are expected to conduct themselves in a manner consistent with ethical 
        and fair gaming practices. Any attempt to manipulate or exploit Bitrate's 
        platform, including but not limited to cheating, fraud, or collusion, will 
        result in immediate account suspension or termination. Bitrate reserves the 
        right to take legal action against violators.
      </>
    },
    {
      header: <>Modification and Termination</>,
      text: <>
        Bitrate reserves the right to modify, suspend, or terminate its services or tournaments 
        at any time, without prior notice. In the event of service termination, Bitrate 
        will make reasonable efforts to notify users and facilitate the withdrawal of 
        remaining funds from their accounts.
      </>
    },
    {
      header: <>Legal Compliance</>,
      text: <>
        Users are responsible for complying with all relevant laws and regulations in their 
        jurisdiction regarding cryptocurrency-based activities and online gaming. Bitrate 
        does not condone or facilitate illegal or unethical behavior and reserves the right 
        to report suspicious activity to the appropriate authorities.
        <br/><br/>
        By accessing or using Bitrate's services, you acknowledge that you have read, 
        understood, and agreed to be bound by these terms and conditions. If you do not 
        agree with any part of these terms, you may not access or use our services.
      </>
    }
  ]

  return (
    <Column style={{ height: "100vh", position: "relative" }} horizontalAlign="center">
      <Blur />
      <InformationContainer>
        <Surface padding={25}>
          <Text align='center' themeColor={themes.colors.gray} themeFont={themes.fonts.h1}>
            Bitrate Terms & Conditions
          </Text>
          <Spacing themeSpace={25} variant='Column' />
          { data.map(item => <Paragraph header={item.header} text={item.text}/>) }
        </Surface>
      </InformationContainer>
    </Column>
  )
});

export type ParagraphProps ={
  header?: ReactNode;
  text?: ReactNode;
}

export const Paragraph: FC<ParagraphProps> = memo(({
  header,
  text
}) => {
  return (
    <>
      <Text style={{fontSize: 16, lineHeight: 1.2, textAlign: 'center'}} themeColor={themes.colors.secondary} themeFont={themes.fonts.t}>
        <b>{header}</b>
      </Text>
      <br/>
      <Text style={{fontSize: 14, lineHeight: 1.3, textAlign: 'justify',textIndent: 30}} themeColor={themes.colors.foreground} themeFont={themes.fonts.t}>
        {text}
      </Text>
      <br/>
    </>
  );
});