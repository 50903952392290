import styled from "styled-components";
import { FontProps, theme } from "../../themes/theme";

export const StyledInput = styled.input<{
    font: FontProps,
    isError: boolean,
 }>`
    width: 55px;
    height: 55px;
    border-radius: 5px;
    border: ${props => props.isError ? `1px solid ${theme.colors.error}` : "0"};
    text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${theme.colors.background};
    font-family: ${props => props.font.family};
    font-size: ${props => props.font.desktopSize};
    font-weight: ${props => props.font.weight};
    color: ${props => props.color !== undefined ? props.color : theme.colors.surface};
    @media (max-width: ${ theme.toMobileSize + 'px'}){
        font-size: ${props => props.font.mobileSize};
        width: 40px;
        height: 40px;
    }
    &:focus{
        border: 1px solid ${theme.colors.surface};
    }
`;