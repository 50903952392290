import styled from "styled-components";

export const FooterBackground = styled.div`
    position: fixed;
    bottom: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: auto;
    padding: 0px 7px;
    z-index: 0;
    height: 52px;
    align-items: center;
    box-shadow: 0 -3px 15px 0 rgba(186, 167, 255, 0.25);
    background: rgba(28, 28, 28, 0.6);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
`;

export const SelectButton = styled.div<{ active: boolean }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 60px;
    height: 40px;
    background: ${(({active}) => active ? '#0DC6A0' : 'transparent')};
    border-radius: 50px;
    &:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  border: 2px solid transparent;
  background: ${(({active}) => active ? 'linear-gradient(140deg, #0DC6A0, #1D0E0E) border-box;' : 'transparent')};
  mask: 
  linear-gradient(#fff 0 0) padding-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
    opacity: 0.5;
}
`

export const IconFooter = styled.img<{size: number}>`
    width: ${(({size}) => size)};
    height: ${(({size}) => size)};
`