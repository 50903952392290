import { useCallback, useMemo } from "react";
import { authSlice } from "../store/reducers/auth-slice";
import { useAppDispatch, useTypedSelector } from "./use-typed-selector";
import { refrashBalance, getUserInfoAction } from "../store/reducers/user-slice";
import { appStatusSlice } from "../store/reducers/app-status-slice";
import { useNavigate } from "react-router";

export const useUser = () => {
    const dispatch = useAppDispatch();

    const userState = useTypedSelector(state => state.user);
    const { status } = useTypedSelector(state => state.appStatus);

    const authToken = localStorage.getItem("authToken");
    const navigate = useNavigate();

    const redirectToLogin = useCallback(() => {
        dispatch(authSlice.actions.reset());
        dispatch(appStatusSlice.actions.clearStatus());
        localStorage.removeItem("authToken");
        navigate('/log-in');
    }, [dispatch, navigate])

    const getUser = useCallback(() => {
        if (authToken === null) {
            redirectToLogin();
        }
        else {
            dispatch(getUserInfoAction({ authToken: authToken }));
        }
    }, [authToken, dispatch, redirectToLogin]);

    const getUSDTBalance = useMemo(() => {
        return userState.user.wallets.find((item) => item.currencies === "USDTERC20")?.amount || 0;
    }, [userState.user.wallets]);

    const getBTRTBalance = useMemo(() => {
        return userState.user.wallets.find((item) => item.currencies === "BTRT")?.amount || 0;
    }, [userState.user.wallets]);

    const getPicture = useMemo(() => {
        return userState.user.picture;
    }, [userState.user.picture])

    const refreshUserBalance = useCallback(() => {
        dispatch(refrashBalance({ authToken: userState.authToken }));
    }, [dispatch, userState.authToken])

    return {
        user: userState.user,
        authToken: userState.authToken,
        redirectToLogin: redirectToLogin,
        getPWAUser: getUser,
        loading: userState.loading,
        appStatus: status,
        language: userState.user.language,
        refreshUserBalance: refreshUserBalance,
        getUSDTBalance: getUSDTBalance,
        getBTRTBalance: getBTRTBalance,
        getPicture: getPicture,
    }
}