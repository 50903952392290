import styled from "styled-components";
import { themes } from "../../../../themes/theme";


export const Blur = styled.div`
position: absolute;
width: 100%;
height: 100%;

background: linear-gradient(0deg, rgba(183, 94, 213, 0.1) 53.97%, rgba(34, 255, 54, 0.1) 150.62%);
filter: blur(45px);
`

export const CardTopPlayerStyled = styled.div<{ max: boolean }>`
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    flex-direction: column;
    width: ${(({ max }) => max ? "110px" : "75px")};
    height: ${(({ max }) => max ? "110px" : "75px")};
    border-radius: 50%;
    border: 1px solid ${(({ max }) => max ? themes.colors.gradient : "#AA44DD")};
    background: ${themes.colors.background};
    padding: 10px 10px 0px 10px;
`;

export const GridTopCard = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 7px;
    justify-items: center;
    align-items: end;
`;

export const GridPlayers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ScrollContainer = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    max-width: 300px;
    &::-webkit-scrollbar{
        display: none;
    }
`

export const Slice = styled.div`
    height: 33px;
    width: 1px;
    background: #D6D6D6;
`