import { FC, HTMLAttributes, ReactNode, memo } from "react";
import { WrapperModal } from "./modal.styled";

import { Surface } from "../surface";

export type ModalProps = {
    borderRadius?: number;
    themeColor?: string;
    themeColorBorder?: string;
    height?: number;
    padding?: number | [number?, number?, number?, number?];
    children? : ReactNode;
    closeByExternalClick?: boolean;
    isActive: boolean;
    closePopup: () => void;
} & HTMLAttributes<HTMLDivElement>

export const Modal : FC<ModalProps> = memo(({
    borderRadius=30, 
    height, 
    padding, 
    children, 
    themeColor,
    themeColorBorder,
    closeByExternalClick=true,
    isActive,
    closePopup,
     ...rest }) => {
    
    const handleSurfaceClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return(
        <WrapperModal isActive={isActive} onClick={closeByExternalClick ? closePopup : () => {}}>
            <Surface
                style={{ zIndex: 10000 }} 
                themeColor={themeColor} 
                themeColorBorder={themeColorBorder} 
                radius={`${borderRadius}px ${borderRadius}px 0px 0px`} 
                height={height} 
                padding={padding  !== undefined ? padding : [25,10]} 
                onClick={handleSurfaceClick}
                {...rest}>
                    {children}
            </Surface>
        </WrapperModal>
    )
})


