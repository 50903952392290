
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column } from '../../../сomponents/column';
import { BlurMiddlePersonalAccount, BlurTopPersonalAccount, GridImagesContainer, Line, PersonalCard, CustomTransparentTextArea } from './personal-account.styled';
import { Image } from "../../../сomponents/image";
import { Row } from '../../../сomponents/row';
import { Spacing } from '../../../сomponents/spacing';
import { Text } from '../../../сomponents/text';
import { themes } from '../../../themes/theme';
import edit from '../../../ui-kit/assets/edit.svg';
import confirmEdit from '../../../ui-kit/assets/confirm.svg';
import teather from '../../../images/teather-icon.svg';
import games from '../../../images/acttive-games-icon.svg';
import security from '../../../images/securite.svg';
import statistic from '../../../images/statistics.svg';
import referral from '../../../images/referal.svg';
import support from '../../../images/icon-support.svg';
import arrow from '../../../images/go-arrow.svg';
import language from '../../../ui-kit/assets/language.svg';
import { Pressable } from '../../../сomponents/pressable';
import { Surface } from '../../../ui-kit/surface';
import { Circle } from '../../../ui-kit/circle';
import { Level } from '../../../ui-kit/level';
import { UserInfo } from '../../../store/reducers/user-slice';
import { Popup } from '../../../ui-kit/popup';

import { ScrollView } from '../../../ui-kit/scroll-view';
import { LogoInfo } from '../../../store/reducers/new/personal-account-slice';
import { CircleLoading } from '../../../ui-kit/circle-loading';
import { Select } from '../../../ui-kit/select';
import { Modal } from '../../../ui-kit/modal';
import { ActiveRoom } from '../../../store/reducers/sit-and-go/rooms-slice';
import { Button as ButtonActiveRoom } from '../../../ui-kit/button';
import { Icon } from '../../../ui-kit/icon';
import duration from '../../../ui-kit/assets/duration.svg';
import { GameStatus } from '../../../api/sit-go/game-api';
import { formatTime } from '../../../data/room-info';
import { FormatNumber } from '../../../data/format-numbers';
import { statisticsRoute } from '../statistics/statistics.props';
export const buttons = [
  {
    title: "Active Games",
    icon: games,
    url: undefined,
  },
  {
    title: "Security",
    icon: security,
    url: undefined,
  },
  {
    title: "Statistics",
    icon: statistic,
    url: statisticsRoute,
  },
  {
    title: "Referral",
    icon: referral,
    url: '/referral',
  },
  {
    title: "Support",
    icon: support,
    url: undefined,
  },
]

const languages = [
  {
    id: 1,
    text: 'English / USDT',
    image: language,
    data: 'en'
  },
  {
    id: 2,
    text: 'Russian / USDT',
    image: language,
    data: 'ru'
  }
]

export type PersonalAccountViewProps = {
  user: UserInfo;
  openPage: (url: string) => void;
  getLogos: () => void;
  playerName: string;
  locale: string | null
  updateUserAvatar: (value: string) => void;
  logos: LogoInfo[];
  loadingLogos: "idle" | "loading" | "success" | "error";
  updatePlayerName: (onSuccess?: () => void) => void;
  setPlayerName: (value: string) => void;
  btrt: number;
  updateLanguage: (value: string) => void;
  sitgoRooms: ActiveRoom[];
  bitrollRooms: ActiveRoom[];
  onGoRoom: (type: string, roomId: number, currency: string) => void;
  getRooms: () => void;
  amountGame: number;
  amountWin: number;
};
export const PersonalAccountView: FC<PersonalAccountViewProps> = memo(({
  user,
  btrt,
  openPage,
  locale,
  getLogos,
  updateUserAvatar,
  loadingLogos,
  logos,
  playerName,
  updatePlayerName,
  setPlayerName,
  updateLanguage,
  sitgoRooms,
  bitrollRooms,
  onGoRoom,
  getRooms,
  amountGame,
  amountWin
}) => {
  const [isActiveLogosPopup, setIsActiveLogosPopup] = useState<boolean>(false);

  const openLogosPopup = useCallback(() => {
    getLogos();
    setIsActiveLogosPopup(true);
  }, [getLogos])

  const closeLogosPopup = useCallback(() => {
    setIsActiveLogosPopup(false);
  }, [])

  const getCurrentLocale = useMemo(() => {
    return languages.find(language => language.data === locale) || languages[0];
  }, [locale])


  const [activeGame, setActiveGame] = useState<boolean>(false);
  const closeActiveGame = useCallback(() => {
    setActiveGame(false);
  }, []);

  const onActiveGames = useCallback(() => {
    getRooms();
    setActiveGame(true);
  }, [getRooms]);

  return (
    <Column horizontalAlign="center">
      <BlurTopPersonalAccount />
      <BlurMiddlePersonalAccount />
      <Profile
        amountGame={amountGame}
        amountWin={amountWin}
        btrt={btrt}
        setPlayerName={setPlayerName}
        playerName={playerName}
        updatePlayerName={updatePlayerName}
        user={user}
        openLogosPopup={openLogosPopup} />
      <Spacing variant="Column" themeSpace={12} />
      <Column horizontalAlign="center" padding={[0, 20]}>
        {buttons.map((item, index) =>
          <Button onClick={item.title === "Active Games" ? onActiveGames : item.url ? () => openPage(item.url) : () => { }} title={item.title} icon={item.icon} index={index} />
        )}
        <Spacing variant="Column" themeSpace={10} />
        <Select onSelect={updateLanguage} selectedItem={getCurrentLocale} items={languages} />
        <Spacing variant="Column" themeSpace={20} />
        <Line />
        <Column style={{ zIndex: 2 }} padding={[0, 5]}>
          <Spacing variant="Column" themeSpace={20} />
          <Pressable onClick={() => openPage('/rules')}>
            <Text themeFont={themes.fonts.ht1} themeColor={themes.colors.gray}>Games Rules</Text>
          </Pressable>
          <Spacing variant="Column" themeSpace={25} />
          <Pressable onClick={() => openPage('/privacy-policy')}>
            <Text themeFont={themes.fonts.ht1} themeColor={themes.colors.gray}>Privacy Policy</Text>
          </Pressable>
          <Spacing variant="Column" themeSpace={25} />
          <Pressable onClick={() => openPage('/terms-and-conditions')}>
            <Text themeFont={themes.fonts.ht1} themeColor={themes.colors.gray}>Terms&Conditions</Text>
          </Pressable>
          <Spacing variant="Column" themeSpace={25} />
        </Column>
      </Column>
      <Popup width={280} isActive={isActiveLogosPopup} closePopup={closeLogosPopup} padding={[40, 20, 20, 20]}>
        <ScrollView style={{ height: 245 }}>
          <CircleLoading state={loadingLogos} />
          <GridImagesContainer>
            {
              logos.map(item =>
                <Pressable onClick={() => updateUserAvatar(item.url)}>
                  <Surface width={70} height={70} radius={35}>
                    <Image src={item.url} size={70} />
                  </Surface>
                </Pressable>
              )
            }
          </GridImagesContainer>
        </ScrollView>
      </Popup>
      <Modal isActive={activeGame} closePopup={closeActiveGame}>
        <Column padding={[0, 14, 14, 14]} style={{ height: "100%" }} horizontalAlign="center">
          <Text themeFont={themes.fonts.h1}>Active <span style={{ color: `${themes.colors.secondary}` }}>Games</span></Text>
          <Spacing themeSpace={20} variant="Column" />
          <ScrollView style={{ maxHeight: "310px" }}>
            {sitgoRooms.length > 0 &&
              <>
                <Text style={{ marginLeft: "5px" }} themeFont={themes.fonts.h4} themeColor={themes.colors.gray}>SIT&GO</Text>
                <Spacing variant="Column" themeSpace={15} />
              </>
            }
            {sitgoRooms.map((item) =>
              <>
                <ActivaCard currency={item.currencies} status={item.status} onClick={onGoRoom} type={item.type} roomId={item.id} time={item.recovery} />
                <Spacing variant="Column" themeSpace={8} />
              </>
            )}
          </ScrollView>
        </Column>
      </Modal>
    </Column>
  );
});

type ProfileProps = {
  playerName: string;
  user: UserInfo;
  btrt: number;
  updatePlayerName: (onSuccess?: () => void) => void;
  openLogosPopup: () => void;
  setPlayerName: (value: string) => void;
  amountGame: number;
  amountWin: number;
}
const Profile: FC<ProfileProps> = memo(({ user, btrt, openLogosPopup, updatePlayerName, playerName, setPlayerName, amountGame, amountWin }) => {
  const statistics = [
    {
      amount: btrt,
      title: "BTRT Token",
    },
    {
      amount: amountGame,
      title: "Total Games",
    },
    {
      amount: amountWin,
      title: "Total Wins",
    }
  ]
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const unblockEditable = useCallback(() => {
    setIsEditable(true);
  }, []);

  const blockEditable = useCallback(() => {
    setIsEditable(false);
  }, []);

  const updatePlayerNameClick = useCallback(() => {
    updatePlayerName(blockEditable);
  }, [updatePlayerName, blockEditable])

  return (<Column style={{ position: "relative" }} horizontalAlign='center' padding={[50, 20, 0, 20]}>
    <Surface
      radius={25}
      onClick={isEditable ? updatePlayerNameClick : unblockEditable}
      style={{
        position: "absolute",
        width: 'auto',
        height: 'auto',
        cursor: 'pointer',
        zIndex: "10",
        top: "50px", right: "20px"
      }}>
      <Column style={{ width: 35, height: 35 }} horizontalAlign='center' verticalAlign='center'>
        <Image size={isEditable ? 14 : 10} src={isEditable ? confirmEdit : edit} />
      </Column>
    </Surface>
    <PersonalCard>
      <Column style={{ maxWidth: "230px", width: "100%" }} horizontalAlign="center">
        <Row style={{ width: "100%" }} horizontalAlign='flex-start' verticalAlign='flex-start'>
          <Circle style={{ marginTop: "-25px", flexShrink: 0 }} size={70}>
            <Image style={{ position: "absolute", cursor: 'pointer' }} onClick={openLogosPopup} src={user.picture} size={68} />
          </Circle>
          <Spacing variant="Row" themeSpace={8} />
          <TransparentTextArea
            value={playerName}
            setValue={setPlayerName}
            maxLength={16}
            isEditable={isEditable}
          />
        </Row>
        <Spacing themeSpace={10} variant="Column" />
        <Row style={{ width: "100%" }} horizontalAlign="space-around" verticalAlign="center">
          {statistics.map((item, index) =>
            <Statistics key={index} title={item.title} amount={item.amount} />
          )}
        </Row>
        <Spacing themeSpace={12} variant="Column" />
        <Level
          start={
            <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>{`Level ${user.level}`}</Text>
          }
          end={
            <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>{`${Number.parseInt(user.level) + 1} lvl`}</Text>
          }
          precent={Number.parseInt(user.levelPercent)}
        />
        <Spacing variant="Column" themeSpace={20} />
        <Balance balance={0} pnlPercent={0} pnlAmount={0} />
      </Column>
    </PersonalCard>
  </Column>)
})
export type StatisticsProps = {
  title: string;
  amount: number;
}
export const Statistics: FC<StatisticsProps> = memo(({ title, amount }) => {
  return (
    <Surface radius={10} width={73} height={45}>
      <Column style={{ height: "100%" }} verticalAlign="center" horizontalAlign="center">
        <Text themeFont={themes.fonts.h5}>{FormatNumber(amount)}</Text>
        <Spacing themeSpace={5} variant="Column" />
        <Text themeFont={themes.fonts.t}>{title}</Text>
      </Column>
    </Surface>
  )
})

type BalanceProps = {
  balance: number;
  pnlPercent: number;
  pnlAmount: number;
}
const Balance: FC<BalanceProps> = memo(({ balance, pnlAmount, pnlPercent }) => {
  return (
    <Column horizontalAlign="center" padding={[0, 0, 0, 30]}>
      <Surface style={{ display: "flex", alignItems: "center", position: "relative", width: "100%" }} radius={10} width={210} height={55}>
        <Surface style={{ left: "-30px", position: "absolute" }} radius={50} width={60} height={60}>
          <Column style={{ height: "100%" }} verticalAlign="center" horizontalAlign="center">
            <Image src={teather} size={25} />
          </Column>
        </Surface>
        <Spacing variant="Row" themeSpace={45} />
        <Column style={{ height: "100%" }} verticalAlign="center">
          <Text themeColor={themes.colors.surface} themeFont={themes.fonts.b}>{`$${balance}`}</Text>
          <Spacing variant="Column" themeSpace={5} />
          <Row verticalAlign="center">
            <span style={{
              background: "#193E36",
              padding: "3px",
              borderRadius: "5px"
            }}>
              <Text themeColor={themes.colors.success} themeFont={themes.fonts.t}>{`${pnlPercent}%`}</Text>
            </span>
            <Spacing variant="Row" themeSpace={5} />
            <Text themeColor={themes.colors.surface} themeFont={themes.fonts.t}>PnL</Text>
            <Spacing variant="Row" themeSpace={2} />
            <Text themeColor={themes.colors.success} themeFont={themes.fonts.t}>{`+${pnlAmount} USDT`}</Text>
          </Row>
        </Column>
      </Surface>
    </Column>
  )
})
type ButtonProps = {
  icon: string;
  title: string;
  index: number;
  onClick: () => void;
}
const Button: FC<ButtonProps> = memo(({ index, icon, title, onClick }) => {
  return (
    <Column horizontalAlign="center">
      <Pressable style={{ width: "100%" }} onClick={onClick}>
        <Surface style={{ width: "100%" }} radius={20} height={48} width={0}>
          <Row style={{ width: "100%", height: "100%" }} padding={[10, 10, 10, 15]} horizontalAlign="space-between" verticalAlign="center">
            <Row verticalAlign="center">
              <Image src={icon} size={20} />
              <Spacing variant="Row" themeSpace={10} />
              <Text themeFont={themes.fonts.ht1} themeColor={themes.colors.foreground}>{title}</Text>
            </Row>
            <Image src={arrow} size={24} />
          </Row>
        </Surface>
      </Pressable>
      {index < buttons.length && <Spacing variant="Column" themeSpace={5} />}
    </Column>
  )
})


type TransparentTextAreaProps = {
  maxLength?: number;
  value: string;
  setValue: (value: string) => void;
  isEditable?: boolean;
}
const TransparentTextArea: FC<TransparentTextAreaProps> = memo(({ maxLength, value, setValue, isEditable = false }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = textareaRef.current;

    const resizeTextarea = () => {
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    if (textarea) {
      textarea.addEventListener('input', resizeTextarea);
      resizeTextarea();
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('input', resizeTextarea);
      }
    };
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <CustomTransparentTextArea
      ref={textareaRef}
      maxLength={32}
      value={value}
      spellCheck="false"
      onChange={handleChange}
      readOnly={!isEditable}
    />
  )
})


type ActiveCardProps = {
  status: GameStatus;
  onClick: (type: string, roomId: number, currency: string) => void;
  currency: string;
  time: number;
  type: string;
  roomId: number
}

export const ActivaCard: FC<ActiveCardProps> = memo(({ status, time, onClick, type, roomId, currency }) => {
  const click = useCallback(() => {
    onClick(type, roomId, currency)
  }, [onClick, roomId, type, currency]);

  const [state, setState] = useState<GameStatus>(status);
  const [t, setT] = useState<number>(time < 0 ? - time : time);

  useEffect(() => {
    const interval = setInterval(() => {
      setT(prev => prev - 1);
    }, 1000);

    if (t === 0) {
      clearInterval(interval);
      if (state === "STARTED")
        setState("ENDED");
    }

    return () => clearInterval(interval);
  }, [state, t])
  return (
    <Surface radius={50} themeColorBorder="#504B6A" height={50} width="100%">
      <Row padding={[0, 5, 0, 15]} verticalAlign="center" horizontalAlign="space-between" style={{ width: "100%", height: "100%" }}>
        <Row style={{ height: "100%" }} verticalAlign="center">
          <Icon themeColor="#504B6A" size={40} src={duration} />
          <Spacing variant="Row" themeSpace={10} />
          <Column>
            <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>{state === "STARTED" ? "Tournament ends in" : "Status"}</Text>
            <Text themeFont={themes.fonts.h4}>{state === "STARTED"
              ?
              `${formatTime(t).slice(0, 2)}h : ${formatTime(t).slice(3, 5)}m : ${formatTime(t).slice(6, 8)}s`
              :
              state}
            </Text>
          </Column>
        </Row>
        {state !== "ENDED" &&
          <ButtonActiveRoom height={40} width={110} onClick={click}>
            <Text themeFont={themes.fonts.h3} themeColor={themes.colors.background}>Go</Text>
          </ButtonActiveRoom>}
      </Row>
    </Surface>
  )
})

