import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SitAndGoRoomsProps } from './sit-and-go-rooms.props';
import { FilterProps, SitAndGoBtrtRoomsView } from './sit-and-go-rooms.view';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../hooks/user-hook';
import { useAppDispatch, useTypedSelector } from '../../../../hooks/use-typed-selector';
import { usePersonalAccount } from '../../personal-account/personal-account.props';
import { useTelegramBack } from '../../../../hooks/telegram-button';
import { getSITGORooms } from '../../../../store/reducers/sit-and-go/rooms-slice';


export const SitAndGoRooms: FC<SitAndGoRoomsProps> = memo(() => {

  const { authToken, getUSDTBalance, user } = useUser();
  const { rooms } = useTypedSelector(state => state.roomSit);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [toggle, setToggle] = useState<"ALL" | "LIVE" | "Premium">("ALL");
  const onToggle = useCallback((state: "ALL" | "LIVE" | "Premium") => {
    if (state !== "Premium") {
      setToggle(state);
    }
  }, [])

  const [duration, setDuration] = useState<FilterProps[]>([
    { zone: "m", param: "FIVE", hidden: true },
    { zone: "m", param: "FIFTEEN", hidden: false },
    { zone: "h", param: "HOUR", hidden: false },
    { zone: "h", param: "FOURHOURS", hidden: false }
  ]);

  const [players, setPlayers] = useState<FilterProps[]>([
    { param: "TWO", hidden: true },
    { param: "FOUR", hidden: false },
    { param: "SIX", hidden: false },
  ]);

  const onFilter = useCallback((state: "Player" | "Time", filter: FilterProps[]) => {
    if (state === "Player") {
      setPlayers(filter);
    } else if (state === "Time") {
      setDuration(filter)
    }
  }, []);

  const getFilterData = useCallback((filter: FilterProps[]) => {
    const item = filter.find(data => data.hidden === true);
    if (item === undefined) {
      return null;
    }
    else {
      return item.param
    }
  }, [])

  useEffect(() => {
    const player = getFilterData(players);
    const time = getFilterData(duration);

    if (player !== null && time !== null) {
      dispatch(getSITGORooms({ authToken: authToken, players: player, filter: toggle, time: time }));
    }
  }, [authToken, dispatch, duration, getFilterData, players, toggle]);

  const onClickRoom = useCallback((roomId: number, currency: string) => {
    navigate(`/sit-and-go/${roomId}/${currency}`);
  }, [navigate]);

  const sortRooms = useMemo(() => {
    if (rooms.length > 0) {
      return [...rooms].sort((item, item2) => {
        if (item.waiting < item2.waiting)
          return 1
        else if (item.waiting > item2.waiting) {
          return -1
        }
        else {
          return 0
        }
      });
    }
    else {
      return []
    }
  }, [rooms]);

  const presonalAccount = usePersonalAccount();
  const onPersonalAccount = useCallback(() => {
    presonalAccount({});
  }, [presonalAccount]);

  const onBack = useCallback(() => {
    navigate('/');
  }, [navigate])
  useTelegramBack(onBack);

  return (
    <SitAndGoBtrtRoomsView
      onProfile={onPersonalAccount}
      onClickRoom={onClickRoom}
      rooms={sortRooms}
      filter={toggle}
      players={players}
      duration={duration}
      onToggle={onToggle}
      onFilter={onFilter}
      balance={getUSDTBalance}
      picture={user.picture}
    />
  );
});
