
import { FC, memo, ReactNode } from 'react';
import { Blur, InformationContainer } from './privacy-policy.styled';
import { Column } from '../../../ui-kit/column';
import { Text } from '../../../ui-kit/text';
import { themes } from '../../../ui-kit/themes/theme';
import { Spacing } from '../../../ui-kit/spacing';
import { Surface } from '../../../ui-kit/surface';

export type PrivacyPolicyViewProps = {

};
export const PrivacyPolicyView: FC<PrivacyPolicyViewProps> = memo(() => {

  const data = [
    {
      header: <>Introduction</>,
      text: <>
        Bitrate is committed to protecting the privacy of its users and complies 
        with all applicable laws, principles, and regulations related to data 
        protection. This Privacy Policy outlines the information administration 
        practices of Bitrate and its affiliated services.
      </>
    },
    {
      header: <>Consent</>,
      text: <>
        By using Bitrate's services, including the website and mobile application, 
        users consent to the terms of this Privacy Policy and its information 
        administration practices. Bitrate may provide additional disclosures related to privacy practices, 
        which will apply supplementary to this Privacy Policy.
      </>
    },
    {
      header: <>Changes to the Privacy Policy</>,
      text: <>
        Bitrate reserves the right to modify or update this Privacy 
        Policy at any time. Users are encouraged to review this 
        policy periodically for any changes. Significant amendments 
        will be communicated to users via email.
      </>
    },
    {
      header: <>Applicability</>,
      text: <>
        This Privacy Policy applies to all users of Bitrate's services, 
        including individuals, merchants, and legal entities. Users 
        include those who use and/or purchase Bitrate or affiliated services.
      </>
    },
    {
      header: <>Personal Information We Collect</>,
      text: <>
        Bitrate collects only essential information necessary for the provision of its services. This may include:
        <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
          <li>Identification Information: such as email, phone number, Telegram data. 
            In case of requirement to pass KYC check additional identification 
            information can be required and collected.</li>
          <li>Financial Information: transaction history, payment details.</li>
          <li>Any other information required based on user interaction and relationship with Bitrate.</li>
        </ul>
      </>
    },
    {
      header: <>Purpose of Information Collection</>,
      text: <>
        Bitrate collects personal information for the following purposes:
        <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
          <li>To improve user experience and personalize services.</li>
          <li>To protect users from fraudulent activities and ensure security.</li>
          <li>To comply with regulatory requirements, including Know Your 
            Customer (KYC) and Anti Money Laundering (AML) regulations.</li>
          <li>To enforce terms and agreements with users.</li>
        </ul>
      </>
    },
    {
      header: <>Sharing Personal Information</>,
      text: <>
        Bitrate may share personal information with:
        <ul style={{paddingLeft: 30, textIndent: 0, marginTop: 8}}>
          <li>Service providers and partners involved in providing Bitrate's services.</li>
          <li>Regulatory authorities to comply with legal obligations.</li>
          <li>Entities involved in business transactions or mergers with Bitrate.</li>
        </ul>
      </>
    },
    {
      header: <>Protection of Personal Information</>,
      text: <>
       Bitrate implements security measures to protect user data 
       from unauthorized access, alteration, or disclosure. These 
       measures include physical, electronic, and procedural safeguards.
      </>
    },
    {
      header: <>Use of Cookies</>,
      text: <>
        Bitrate may use cookies to enhance user experience and analyze 
        website traffic. Users can manage cookie preferences through 
        their browser settings.
      </>
    },
    {
      header: <>User Rights</>,
      text: <>
        Users have the right to access, correct, object to, 
        and delete personal information held by Bitrate. Requests 
        can be made through the provided contact information.
      </>
    },
    {
      header: <>Contact Us</>,
      text: <>
        For inquiries regarding this Privacy Policy or personal 
        information, users can contact Bitrate at the support window.
      </>
    }
  ]

  return (
    <Column style={{ height: "100vh", position: "relative" }} horizontalAlign="center">
      <Blur />
      <InformationContainer>
        <Surface padding={25}>
          <Text align='center' themeColor={themes.colors.gray} themeFont={themes.fonts.h1}>
            Privacy Policy
          </Text>
          <Spacing themeSpace={25} variant='Column' />
          { data.map(item => <Paragraph header={item.header} text={item.text}/>) }
        </Surface>
      </InformationContainer>
    </Column>
  )
});

export type ParagraphProps ={
  header?: ReactNode;
  text?: ReactNode;
}

export const Paragraph: FC<ParagraphProps> = memo(({
  header,
  text
}) => {
  return (
    <>
      <Text style={{fontSize: 16, lineHeight: 1.2, textAlign: 'center'}} themeColor={themes.colors.secondary} themeFont={themes.fonts.t}>
        <b>{header}</b>
      </Text>
      <br/>
      <Text style={{fontSize: 14, lineHeight: 1.3, textAlign: 'justify',textIndent: 30}} themeColor={themes.colors.foreground} themeFont={themes.fonts.t}>
        {text}
      </Text>
      <br/>
    </>
  );
});