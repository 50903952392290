
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column } from '../../../../сomponents/column';
import { PinkBlur, GreenBlur, Panel, Ring, SwipeIconWrapper, CoinContainer, GameWrapper, AnimateText } from './swiper.styled';
import { themes } from '../../../../themes/theme';
import { Image } from '../../../../сomponents/image';
import { Text } from '../../../../сomponents/text';
import { Row } from '../../../../сomponents/row';
import { Spacing } from '../../../../сomponents/spacing';
import coin from "../../../../images/coin.svg";
import daily from "../../../../images/daily.svg";
import energy from "../../../../images/energy.svg";
import { Level } from '../../../../ui-kit/level';
import swipeIcon from '../../../../ui-kit/assets/swipe-icon.svg';
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import swiperCoin from '../../../../ui-kit/animation/coin.json';
import { Coordinates, MobileSwipe } from '../../../../ui-kit/mobile-swiper/mobile-swiper';
import { Touch } from '../../../../ui-kit/touch';

export type SwiperViewProps = {
  btrt: number;
  time: number;
  daily: number;
  onClick: (callback: () => void) => void;
  disable: boolean;
  dailyMaxValue: number;
  onIntervalClick: () => void;
};
export const SwiperView: FC<SwiperViewProps> = memo(({ time, onIntervalClick, onClick, btrt, daily, dailyMaxValue, disable }) => {
  const animation = useRef<LottieRefCurrentProps | null>(null);
  const playAnimation = useCallback(() => {
    animation.current?.goToAndPlay(0);
  }, [])

  useEffect(() => {
    animation.current?.goToAndStop(0)
  }, []);

  const [tapAnimationElements, setTapAnimationElements] = useState<AnimationElementInfo[]>([]);

  const addAnimationElement = useCallback((amount: number, vertical: number, horizontal: number) => {
    const id = Date.now().toString();
    const newElement = { id, amount, vertical, horizontal };

    setTapAnimationElements(prev => [...prev, newElement]);

    setTimeout(() => {
      setTapAnimationElements(prev => prev.filter(elem => elem.id !== id));
    }, 3000)
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  const onCoin = useCallback((coordinats: Coordinates) => {
    if ((Math.abs(coordinats.deltaX) > Math.abs(coordinats.deltaY)) && disable === false) {
      onClick(() => {
        playAnimation();
        if (containerRef.current !== null) {
          const { left } = containerRef.current.getBoundingClientRect();
          addAnimationElement(10, coordinats.deltaY, coordinats.deltaX = left);
        }
      });
    }
  }, [addAnimationElement, disable, onClick, playAnimation]);

  const onTapCoin = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disable) {
      onIntervalClick();
      const container = e.currentTarget;
      const { left, top } = container.getBoundingClientRect();
      addAnimationElement(1, e.clientY - top, e.clientX - left);
    }
  }, [addAnimationElement, disable, onIntervalClick])

  return (
    <GameWrapper>
        <Column style={{ height: "100%", position: "relative" }} horizontalAlign="center">
          <PanelGame dailyMaxValue={dailyMaxValue} dailyFarm={daily} time={time} btrt={btrt} />
          <Spacing variant="Column" themeSpace={25} />
          <Column style={{ position: "relative" }} horizontalAlign="center">
            <MobileSwipe onSwipe={onCoin}>
              <Column style={{ position: "relative" }} horizontalAlign="center">
                <Ring wave={'one'} />
                <Ring wave={'two'}>
                  <SwipeIconWrapper>
                    <Image src={swipeIcon} size={16} />
                  </SwipeIconWrapper>
                </Ring>
                <CoinContainer ref={containerRef}>
                  {tapAnimationElements.map((item) => <AnimateElement vertical={item.vertical} horizontal={item.horizontal} key={item.id} amount={item.amount} />)}
                  <Touch onClick={onTapCoin}>
                    <Lottie
                      lottieRef={animation}
                      animationData={swiperCoin}
                      loop={false}
                      playsInline={true}
                      autoPlay={false}
                    />
                  </Touch>
                </CoinContainer>
                <Spacing themeSpace={30} variant='Column' />
              </Column>
            </MobileSwipe>
          </Column>
          <Spacing themeSpace={10} variant="Column" />
          <Text align='center' themeFont={themes.fonts.h1}>Swipe and Tap
            <br></br>To earn <span style={{ color: `${themes.colors.secondary}` }}>BTRT</span></Text>
          <Spacing themeSpace={10} variant="Column" />
          <Text align='center' themeColor={themes.colors.gray} themeFont={themes.fonts.ht1}>To join our daily<br></br>BITROLLS</Text>
          <Column padding={[40, 0, 40, 0]}></Column>
        </Column>
    </GameWrapper>
  );
});

type PanelGameProps = {
  time: number;
  btrt: number;
  dailyFarm: number;
  dailyMaxValue: number;
}
const PanelGame: FC<PanelGameProps> = memo(({ time, dailyMaxValue, btrt, dailyFarm }) => {
  const timeSliderPrecent = useMemo(() => {
    return -(((time / 5000) * 100) - 100);
  }, [time])

  const dailySliderPrecent = useMemo(() => {
    return (dailyFarm / dailyMaxValue) * 100;
  }, [dailyFarm, dailyMaxValue])
  return (
    <Column horizontalAlign="flex-start">
      <PinkBlur />
      <GreenBlur />
      <Panel color={themes.colors.background}>
        <Column padding={[15, 25, 0, 25]} horizontalAlign='center'>
          <Row verticalAlign="center" horizontalAlign="center" style={{ width: "100%" }}>
            <Image src={coin} size={20} />
            <Spacing themeSpace={10} variant="Row" />
            <Text align="center" themeFont={themes.fonts.h2}>{`${btrt} BTRT`}</Text>
          </Row>
          <Spacing themeSpace={30} variant="Column" />
          <Level
            start={
              <Row verticalAlign="center">
                <Image src={daily} size={12} />
                <Spacing themeSpace={5} variant="Row" />
                <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>{`Daily Farm`}</Text>
              </Row>
            }
            end={<Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>{`${dailyFarm} / ${dailyMaxValue}`}</Text>}
            precent={dailySliderPrecent}
          />
          <Spacing themeSpace={15} variant="Column" />
          <Level
            start={
              <Row verticalAlign="center">
                <Image src={energy} size={12} />
                <Spacing themeSpace={5} variant="Row" />
                <Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>{`Energy`}</Text>
              </Row>
            }
            end={<Text themeColor={themes.colors.gray} themeFont={themes.fonts.ht2}>{time !== 0 && `00:0${time / 1000}`}</Text>}
            precent={timeSliderPrecent}
            themeColor={themes.colors.gradient}
          />
        </Column>
      </Panel>
    </Column>
  )
})

export type AnimateElementProps = {
  amount: number;
  vertical: number;
  horizontal: number;
}
export const AnimateElement: FC<AnimateElementProps> = memo(({ amount, vertical, horizontal }) => {
  return (
    <AnimateText horizontal={horizontal} vartical={vertical}>
      <Text themeFont={themes.fonts.h1}>+{amount}</Text>
    </AnimateText>
  )
})

type AnimationElementInfo = {
  id: string;
  amount: number;
  vertical: number;
  horizontal: number;
}







