
import { FC, memo, useCallback, useState } from 'react';
import { Column } from '../../../ui-kit/column';
import { Text } from '../../../ui-kit/text';
import { themes } from '../../../themes/theme';
import { Spacing } from '../../../ui-kit/spacing';
import { Blur, Line, LineTable } from './statistics.styled';
import { Row } from '../../../ui-kit/row';
import { Pressable } from '../../../ui-kit/pressable';
import { Surface } from '../../../ui-kit/surface';
import { ScrollView } from '../../../ui-kit/scroll-view';
import { StatisticsGame, StatisticsGameInfo } from '../../../store/reducers/personal-account/statistics-slice';

export type StatisticsViewProps = {
  games: StatisticsGame[];
  onClickCard: (roomId: number) => void;
};
export const StatisticsView: FC<StatisticsViewProps> = memo(({ games, onClickCard }) => {
  const [state, setState] = useState<"Left" | "Right">("Left");
  const onTab = useCallback((value: "Left" | "Right") => {
    setState(value);
  }, []);


  return (
    <Column style={{ height: "100vh" }} horizontalAlign="center">
      <Blur />
      <Spacing themeSpace={25} variant="Column" />
      <Text themeFont={themes.fonts.h2}>Statistics</Text>
      <Spacing themeSpace={25} variant="Column" />
      <Tabs state={state} onTab={onTab} />
      <Spacing themeSpace={25} variant="Column" />
      {state === "Left" && <Table onClickCard={onClickCard} statisticsGames={games} />}
    </Column>
  );
});



type TabsProps = {
  state: "Left" | "Right";
  onTab: (value: "Left" | "Right") => void;
}
const Tabs: FC<TabsProps> = memo(({ state, onTab }) => {
  const onLeft = useCallback(() => { onTab("Left") }, [onTab])
  return (
    <Row style={{ width: "100%" }} verticalAlign="center" horizontalAlign="center">
      <Pressable onClick={onLeft} >
        <Column verticalAlign="flex-start" horizontalAlign="flex-end">
          <Column style={{ width: "auto" }} horizontalAlign="center">
            <Text themeFont={themes.fonts.ht1} themeColor={state === "Left" ? themes.colors.primary : themes.colors.gray}>Table</Text>
            <Spacing themeSpace={10} variant="Column" />
            <Line />
          </Column>
        </Column>
      </Pressable>
      <Spacing themeSpace={80} variant="Row" />
      <Pressable>
        <Column verticalAlign="flex-start" horizontalAlign="flex-start">
          <Column style={{ width: "auto" }} horizontalAlign="center">
            <Text style={{ marginLeft: "-15px" }} themeFont={themes.fonts.ht1} themeColor={themes.colors.gray}>Diagram</Text>
            <Spacing themeSpace={10} variant="Column" />
          </Column>
        </Column>
      </Pressable>
    </Row >
  );
})

type TableProps = {
  statisticsGames: StatisticsGame[];
  onClickCard: (roomId: number) => void;
}
const Table: FC<TableProps> = memo(({ statisticsGames, onClickCard }) => {
  return (
    <Column style={{ zIndex: 1 }} padding={[0, 15]} horizontalAlign="center" height={350}>
      <Surface themeColorBorder="#52506D" radius={20} themeColor={themes.colors.background} style={{ height: "100%", width: "100%" }}>
        <Column style={{ height: "100%" }} padding={[20, 15, 4, 15]} horizontalAlign="center">
          <Text themeFont={themes.fonts.h2} themeColor={themes.colors.primary}>My Games</Text>
          <Spacing themeSpace={15} variant="Column" />
          <ScrollView>
            {statisticsGames.map((item) =>
              <Column>
                <TableCard onClickCard={onClickCard} date={item.date} statistics={item.gameInfo} />
                <Spacing themeSpace={10} variant="Column" />
              </Column>
            )}
          </ScrollView>
        </Column>
      </Surface>
    </Column>
  )
});


type TableCardProps = {
  date: string;
  statistics: StatisticsGameInfo[];
  onClickCard: (roomId: number) => void;
}
const TableCard: FC<TableCardProps> = memo(({ date, statistics, onClickCard }) => {
  return (
    <Column horizontalAlign="flex-start">
      <Text themeFont={themes.fonts.ht2} themeColor={themes.colors.secondary}>{date}</Text>
      {statistics.map((item) =>
        <Pressable onClick={() => onClickCard(item.roomId)} style={{ width: "100%" }}>
          <Column horizontalAlign="flex-start">
            <Text themeFont={themes.fonts.t} themeColor={themes.colors.gray}>{item.time}</Text>
            <Row style={{ width: "100%" }} horizontalAlign="space-between" verticalAlign="center">
              <Text themeFont={themes.fonts.h5}>{item.description}</Text>
              <Text themeFont={themes.fonts.h5} themeColor={themes.colors.primary}>{item.prize}</Text>
            </Row>
            <Spacing themeSpace={10} variant="Column" />
            <LineTable />
            <Spacing themeSpace={20} variant="Column" />
          </Column>
        </Pressable>
      )}
    </Column>
  )
})
