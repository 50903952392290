
import { FC, memo, useCallback, useState } from 'react';
import { Swiper } from '../swiper/swiper';
import { SwiperTask } from '../swiper-task';
import { SwiperLeaderboard } from '../swiper-leaderboard';
import { Column } from '../../../../сomponents/column';
import { Footer } from '../../../../ui-kit/footer';
import { SwiperParentProps } from './swiper-parent.props';
import { Lobby } from '../lobby';
import { useNavigate } from 'react-router';
import { useTelegramBack } from '../../../../hooks/telegram-button';
import { Header } from '../../../../ui-kit/header';
import { usePersonalAccount } from '../../personal-account/personal-account.props';
import { useUser } from '../../../../hooks/user-hook';
import { SwiperFortune } from '../swiper-fortune';
import { useSwiperFortune } from '../../../../hooks/swiper/swiper-fortune';


export const SwiperParent: FC<SwiperParentProps> = memo(() => {
  const [type, setType] = useState<"Farm" | "Task" | "Top" | "Games">("Farm");
  const navigate = useNavigate();
  const { user, getUSDTBalance } = useUser();
  const onBack = useCallback(() => {
    navigate('/');
  }, [navigate])
  useTelegramBack(onBack);
  const presonalAccount = usePersonalAccount();
  const onPersonalAccount = useCallback(() => {
    presonalAccount({});
  }, [presonalAccount])

  const {initDataFortune, getAccessibleSpins} = useSwiperFortune();

  const [isOpenFortune,setIsOpenFortune] = useState<boolean>(false);
  const openFortune = useCallback(() => {
    getAccessibleSpins();
    initDataFortune();
    setIsOpenFortune(true);
  }, [initDataFortune, getAccessibleSpins])
  const closeFortune = useCallback(() => {
    setIsOpenFortune(false);
  }, [])
  
  return (
    <Column horizontalAlign="center" style={{ height: '100vh' }}>
      {type !== "Task" &&
        <Column padding={[15, 25, 0, 25]} horizontalAlign='center'>
          <Header
            picture={user.picture}
            onProfile={onPersonalAccount}
            onWallet={() => console.log(1)}
            balance={getUSDTBalance}
          />
        </Column>}
      <SwiperParentView
        type={type}
      />
      <SwiperFortune
        isShow={isOpenFortune}
        closeFortune={closeFortune}/>
      <Footer
        type={type}
        onFortune={openFortune}
        onFarm={() => setType("Farm")}
        onTask={() => setType("Task")}
        onLeaderboard={() => setType("Top")}
        onGames={() => setType('Games')}
      />
    </Column>
  );
});

export type SwiperParentViewProps = {
  type: "Farm" | "Task" | "Top" | "Games";
}
export const SwiperParentView: FC<SwiperParentViewProps> = memo(({ type }) => {
  switch (type) {
    case "Farm": return <Swiper />
    case "Task": return <SwiperTask />
    case "Top": return <SwiperLeaderboard />
    case "Games": return <Lobby />
  }
})
