import { FC, HtmlHTMLAttributes, memo, useCallback, useState } from "react";
import { Surface } from "../surface";
import { Text } from "../text";
import { themes } from "../themes/theme";
import { Row } from "../row";
import { Modal } from "../modal";
import { ScrollView } from "../scroll-view";
import { Spacing } from "../spacing";
import { Pressable } from "../pressable";
import { Image } from "../image";


export type ItemOfSelect = {
    id: number,
    image?: string,
    text: string,
    data?: string,
};

export type SelectProps = {
    items: ItemOfSelect[],
    selectedItem: ItemOfSelect,
    onSelect: (value: string) => void,
};

export const Select: FC<SelectProps> = memo(({items, selectedItem, onSelect}) => {

    const [isOpenList, setIsOpenList] = useState<boolean>(false);
    const closeList = useCallback(() => {
        setIsOpenList(false);
    },[])
    const openList = useCallback(() => {
        setIsOpenList(true);
    },[])
    const onClick = useCallback((value: string) => {
        closeList();
        onSelect(value);
    },[onSelect,closeList])

    return (
        <>
            <ItemView onClick={openList} data={selectedItem}/>
            <Modal closeByExternalClick={true} isActive={isOpenList} closePopup={closeList} padding={[30,25,20,25]}>
                <ScrollView style={{maxHeight: 300}}>
                    {items.map(item => <>
                        <ItemView onClick={() => onClick(item.data ?? item.text)} data={item}/>
                        <Spacing variant='Column' themeSpace={10} />
                    </>)}
                </ScrollView>
            </Modal>
        </>
    );
});

export type ItemViewProps = {
    data: ItemOfSelect,
    onClick: () => void,
} & HtmlHTMLAttributes<HTMLElement>;

export const ItemView: FC<ItemViewProps> = memo(({data,onClick}) => {

    return (
        <>
            <Pressable onClick={onClick} style={{width: '100%'}}>
                <Surface height={40} themeColor={'#261E2E'} themeColorBorder={themes.colors.gradients.foreground}>
                    <Row verticalAlign='center' style={{height: '100%'}}>
                        {
                            data.image !== undefined && <>
                                <Spacing themeSpace={15} variant='Row'/>
                                <Image src={data.image} size={15}/>
                            </>
                        }
                        <Spacing themeSpace={10} variant='Row'/>
                        <Text themeFont={themes.fonts.ht1} themeColor={themes.colors.foreground}>
                            {data.text}
                        </Text>
                    </Row>
                </Surface>
            </Pressable>
        </>
    );
});