
import { FC, memo, useCallback } from 'react';
import { RulesProps } from './rules.props';
import { RulesView } from './rules.view';
import { usePersonalAccount } from '../personal-account/personal-account.props';
import { useTelegramBack } from '../../../hooks/telegram-button';


export const Rules: FC<RulesProps> = memo(() => {
  const personal = usePersonalAccount();
  const onBack = useCallback(() => {
     personal({});
  }, [personal])
  useTelegramBack(onBack);
  return (
      <RulesView/>
    );
});
