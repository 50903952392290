import styled from "styled-components";
import { themes } from "../themes/theme";

export const WrapperPopap = styled.div<{
  isActive: boolean;
}>`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  top: 0;
  z-index: 100;
  left: 0;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background: rgba(24, 24, 24, 0.11);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${({ isActive }) => isActive? "flex" : "none"};
  pointer-events: ${({ isActive }) => isActive? "all" : "none"};
  transition: 0.5s;
`

export const CustomCloseButtom = styled.button`
  position: absolute;
  left: 50%;
  top: -18px;
  transform: translateX(-50%);
  background-color: #1C1C1C;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: 25px;
    border: 2px solid transparent;
    background: linear-gradient(180deg, #835C7C, #240203) border-box;
    mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
      opacity: 0.5;
    }
`;

export const BackgroundCloseButton = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: ${themes.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;