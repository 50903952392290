
import { FC, memo, useCallback } from 'react';
import { TermsAndConditionsProps } from './terms-and-conditions.props';
import { TermsAndConditionsView } from './terms-and-conditions.view';
import { useTelegramBack } from '../../../hooks/telegram-button';
import { usePersonalAccount } from '../personal-account/personal-account.props';



export const TermsAndConditions: FC<TermsAndConditionsProps> = memo(() => {
  const personal = usePersonalAccount();
  const onBack = useCallback(() => {
    personal({});
  }, [personal])

  useTelegramBack(onBack);

  return (
    <TermsAndConditionsView />
  );
});
